import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Img from "@/components/Img";
import { useState } from "react";
import { Menu, Dropdown } from "antd";
import { getLogout } from "@/api/useApi";
import ResetPassword from "./resetPasswode";

import avatar from "@/static/images/header-avatar.png";

const Email = (props: any) => {
  const { isMobile, userEmail, setUserEmail, getAccount, onClose } = props;
  const router = useRouter();
  const locale = router.locale || "en-US";
  const [isShow, setIsShow] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const intl = useIntl();

  const handleLogout = () => {
    getLogout().then((res: any) => {
      if (res?.success) {
        setUserEmail && setUserEmail("");
        onClose && onClose();
        getAccount();
      }
    });
  };

  const list = [
    {
      title: "info",
      href: `/finance/payment?lang=${locale}`,
      onclick: () => {
        onClose && onClose();
        window.open(
          `${global.__webInfo?.proto}${global.__webInfo?.domainName}/finance/payment?lang=${locale}`,
          "_blank"
        );
      },
    },
    {
      title: "profile",
      href: `/account/profile?lang=${locale}`,
      onclick: () => {
        onClose && onClose();
        window.open(
          `${global.__webInfo?.proto}${global.__webInfo?.domainName}/account/profile?lang=${locale}`,
          "_blank"
        );
      },
    },
    {
      title: "reset",
      onclick: () => {
        setModalVisible(true);
      },
    },
    {
      title: "logout",
      onclick: handleLogout,
      class: "red",
    },
  ];

  const menu = (
    <Menu>
      {list.map((item: any) => {
        return (
          <Menu.Item
            onClick={item.onclick}
            key={item.title}
            className={item.class || ""}
          >
            <span onClick={item.onClick}>
              {intl.formatMessage({
                id: `components.header.menu.${item.title}`,
              })}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const handleShowSub = () => {
    const show = isShow;
    setIsShow(!show);
  };
  return (
    <>
      {!isMobile ? (
        <div className="header-email">
          <Img
            boxStyle={{ marginRight: 10 }}
            src={avatar}
            alt={""}
            width={34}
            objectFit="contain"
          />
          <Dropdown overlay={menu} overlayClassName="k-emailStyle">
            <span>{userEmail}</span>
          </Dropdown>
        </div>
      ) : (
        <div className="email-mobile">
          <div
            onClick={handleShowSub}
            className={["hasChildren", isShow ? "show" : ""].join(" ")}
          >
            <div className="item-li" style={{ display: "flex" }}>
              <Img
                boxStyle={{
                  paddingRight: 10,
                  width: "4.75rem",
                  height: "8.75rem",
                  display: "inline-flex",
                }}
                src={avatar}
                alt={""}
                objectFit="contain"
              />
              <span>{userEmail}</span>
            </div>
          </div>
          <ul>
            {list.map((item: any) => {
              return isShow ? (
                <li
                  onClick={item.onclick}
                  key={item.title}
                  className={item.class || ""}
                >
                  {intl.formatMessage({
                    id: `components.header.menu.${item.title}`,
                  })}
                </li>
              ) : null;
            })}
          </ul>
        </div>
      )}
      <ResetPassword
        visible={modalVisible}
        setModalVisible={setModalVisible}
        userEmail={userEmail}
        onClose={onClose}
        handleLogout={handleLogout}
      />
    </>
  );
};
export default Email;
