import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Img from "@/components/Img";
import { Menu, Dropdown } from "antd";
import { useState } from "react";
import { renderTypeName } from "@/utils/utils";
import imgMobileArrowDown from "@/static/images/down.png";
import "./style.less";
type lang = "en-US" | "zh-CN" | "zh-HK" | "fr-FR" | "vi-VN";
const langList = [
  {
    text: "English",
    value: "en-US",
  },
  {
    text: "Français",
    value: "fr-FR",
  },
  {
    text: "Tiếng Việt",
    value: "vi-VN",
  },
  {
    text: "简体中文",
    value: "zh-CN",
  },
  {
    text: "繁體中文",
    value: "zh-HK",
  },
];
// 首页特殊处理
const speacilLink = [
  {
    url: "/",
    link: {
      "en-US": "/en-US",
      "zh-CN": "/zh-CN",
      "zh-HK": "/zh-HK",
      "fr-FR": "/fr-FR",
      "vi-VN": "/vi-VN",
    },
  },
];
const SetLocale = (props: any) => {
  const router = useRouter();
  const lang = router.locale || "en-US";
  const intl = useIntl();
  const { isMobile } = props;
  const [isShow, setIsShow] = useState<boolean>(false);
  const langChange = (key: lang) => {
    const pathname = window.location.pathname;
    const selectPath = speacilLink.find((i) => i.url === pathname);
    if (selectPath) {
      window.location.href = selectPath.link[key];
    } else {
      window.location.href =
        location.pathname.replace(lang, key) + location.search;
    }
  };
  const menu = (
    <Menu>
      {langList.map((item: any) => {
        return (
          <Menu.Item onClick={() => langChange(item.value)} key={item.value}>
            {item.text}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const handleShowSub = () => {
    const show = isShow;
    setIsShow(!show);
  };
  const handleClose = () => {
    props.onClose && props.onClose();
  };
  return !isMobile ? (
    <div className="k-setLocale">
      <Dropdown overlay={menu} overlayClassName="k-setlocaleStyle">
        <div className="k-setLocaleCont">
          <i className="iconfont icon-yuyan"></i>
          <span>
            {renderTypeName(langList, lang, true)
              ? renderTypeName(langList, lang, true)
              : "English"}
          </span>
          <Img
            boxStyle={{ marginLeft: 10, width: 8 }}
            src={imgMobileArrowDown}
            alt={""}
            type="label"
          />
        </div>
      </Dropdown>
    </div>
  ) : (
    <ul className="header-setLocale">
      <li
        onClick={handleShowSub}
        className={[
          "k-setLocale-locale hasChildren",
          isShow ? "show" : "",
        ].join(" ")}
      >
        <span>{intl.formatMessage({ id: "components.header.language" })}</span>
      </li>
      <ul>
        {langList.map((item: any) => {
          return isShow ? (
            <li
              onClick={() => {
                langChange(item.value);
                handleClose();
              }}
              key={item.value}
            >
              {item.text}
            </li>
          ) : null;
        })}
      </ul>
    </ul>
  );
};
export default SetLocale;
